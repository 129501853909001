import React from 'react'

export default function useHideIntercom() {
  React.useEffect(() => {
    setTimeout(() => {
      document.body.classList.add('hide-intercom')
    }, 100)
    return () => {
      document.body.className = document.body.className.replace(
        'hide-intercom',
        ''
      )
    }
  }, [])
}
